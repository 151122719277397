import React from "react"
import Admissionform from "../Components/Admissionform"

const Admissionformp = () => {
    return(
        <div className="pt-20">
            <Admissionform />
        </div>
    )
}

export default Admissionformp;